import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StatusLabel, IconInfoCircle, IconCheckCircle, IconAlertCircle, IconError } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/status-label",
  "title": "StatusLabel",
  "navTitle": "StatusLabel"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <StatusLabel type="success" mdxType="StatusLabel">Completed</StatusLabel>
  <StatusLabel type="alert" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconAlertCircle mdxType="IconAlertCircle" />} mdxType="StatusLabel">
    Needs attention
  </StatusLabel>
  <StatusLabel type="error" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconError mdxType="IconError" />} mdxType="StatusLabel">
    Rejected
  </StatusLabel>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label text should always be provided with a status label.`}</strong>{` Do not use status labels without text or with icons only.`}
        <ul parentName="li">
          <li parentName="ul">{`Icons inside status labels are allowed if they are accompanied with a text label.`}
            <ul parentName="li">
              <li parentName="ul">{`Make sure the icon matches the visual label. Do not add an icon only for visual purposes.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Keep labels short and concise. Use 1-2 words at maximum and do not use labels expanding to two rows.`}</li>
      <li parentName="ul">{`Use status colours consistently. You can find guidelines on when to use each status colour in the `}<InternalLink href="/foundation/design-tokens/colour#ui-colours" mdxType="InternalLink">{`colour guidelines.`}</InternalLink></li>
      <li parentName="ul">{`Status labels are often related to some other element on the page (e.g. table row). Aim to keep the status label and its related elements close to each other so the user can easily interpret the relation.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`StatusLabel comes in five (5) different types. One neutral and four (4) with different status colours.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <StatusLabel mdxType="StatusLabel">Neutral</StatusLabel>
  <StatusLabel type="info" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="StatusLabel">
    Info
  </StatusLabel>
  <StatusLabel type="success" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="StatusLabel">
    Success
  </StatusLabel>
  <StatusLabel type="alert" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="StatusLabel">
    Alert
  </StatusLabel>
  <StatusLabel type="error" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="StatusLabel">
    Error
  </StatusLabel>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-an-icon",
      "style": {
        "position": "relative"
      }
    }}>{`With an icon`}<a parentName="h4" {...{
        "href": "#with-an-icon",
        "aria-label": "with an icon permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`An icon can be used on the left side of the StatusLabel's text label. The icon should match with the text label and not be used only for visual purposes.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <StatusLabel iconLeft={<IconInfoCircle mdxType="IconInfoCircle" />} mdxType="StatusLabel">Neutral</StatusLabel>
  <StatusLabel type="info" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconInfoCircle mdxType="IconInfoCircle" />} mdxType="StatusLabel">
    Info
  </StatusLabel>
  <StatusLabel type="success" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconCheckCircle mdxType="IconCheckCircle" />} mdxType="StatusLabel">
    Success
  </StatusLabel>
  <StatusLabel type="alert" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconAlertCircle mdxType="IconAlertCircle" />} mdxType="StatusLabel">
    Alert
  </StatusLabel>
  <StatusLabel type="error" style={{
        marginLeft: 'var(--spacing-s)'
      }} iconLeft={<IconError mdxType="IconError" />} mdxType="StatusLabel">
    Error
  </StatusLabel>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      